/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import 'ngx-toastr/toastr';

body {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    background-color: #F7F7F7;
}

.fs-28 {
    font-size: 28px !important;
}

.fs-24 {
    font-size: 24px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-10 {
    font-size: 10px !important;
}

.bg-white {
    background-color: white;
}

.white-color {
    color: white;
}

.white-block {
    // padding: 30px;
    border-radius: 5px;
    background-color: white;
}

.gray-block {
    border-radius: 5px;
    background-color: #F7F7F7;
    border: 2px solid #F7F7F7;
}

// .gray-block:hover {
//     border: 2px solid #3AA38D;
//     background-color: white;
// }

.gray-block-selected {
    border: 2px solid #3AA38D;
    background-color: white;
}

.green-light-block {
    border-radius: 5px;
    background-color: #DBEBE7 !important;
}

.green-color {
    color: #1F563F;
}

.link {
    color: #1F563F;
}

.text-underline {
    text-decoration: underline;
}

.clickable {
    cursor: pointer;
}

.text-blur {
    opacity: 0.6;
}

.btn {
    font-size: 14px;
}

.btn-standazd {
    background-color: #3AA38D;
    color: white;
    border-radius: 5px;
    padding: 10px;
}

.btn-standazd:hover {
    background-color: #3AA38D;
    opacity: 0.8;
    color: white;
}

.btn-cancel {
    background-color: white !important;
    border: 1px #CDCDCD solid;
    color: black !important;
}

.btn-cancel:hover {
    background-color: white !important;
    border: 1px #CDCDCD solid;
    color: black !important;
}

.btn-remove {
    background-color: white !important;
    border: 1px #7C0000 solid;
    color: #7C0000 !important;
}

.btn-remove:hover {
    background-color: white !important;
    border: 1px #7C0000 solid;
    color: #7C0000 !important;
}

.p-steps .p-steps-item:first-child:before {
    content: " ";
    width: 50%;
    left: unset !important;
    right: 0;
}

.p-steps .p-steps-item:last-child:before {
    content: " ";
    width: 50%;
}

.p-steps .p-steps-item.active-step .p-steps-number {
    background-color: #E99937 !important;
    color: white !important;
}

.p-steps .p-steps-item.active-step .p-steps-title {
    font-weight: 700 !important;
    color: #495057 !important;
}

.p-steps-title {
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
}

.p-steps-number {
    font-family: 'Montserrat', sans-serif;
}

.p-steps .p-steps-item .p-steps-number {
    background-color: #F7F7F7 !important;
    color: gray !important;
}


.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    min-width: 2rem !important;
    height: 2rem !important;
}

.p-steps .p-steps-item:before {
    top: 53% !important;
}

.home {
    .p-steps .p-steps-item:before {
        border-top: 2px solid #dee2e6 !important;
        top: 45% !important;
    }
}

.modal-full-with-bottom {
    width: 100% !important;
}

.popover-bottom {
    @media (max-width: 767px) {
        position: absolute !important;
        bottom: 0px !important;
        width: 100% !important;
        left: 0px !important;
        top: unset !important;
    }
}

.p-dialog .p-dialog-content:last-of-type {
    @media (min-width: 768px) {
        border-bottom-right-radius: 15px !important;
        border-bottom-left-radius: 15px !important;
    }
}

.p-dialog .p-dialog-header {
    border-top-right-radius: 15px !important;
    border-top-left-radius: 15px !important;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.p-dialog .p-dialog-content {
    color: black !important;
}

.dialog-confirm {
    .p-dialog-title {
        font-size: 16px !important;
    }
}

.step-small {
    .p-steps-title {
        font-size: 12px !important;
    }
}

.input-standazd {
    width: 100%;
    height: 40px;
    border: unset;
    padding-left: 10px;
    background-color: #F7F7F7;
    border: none;
    outline: none;
    color: black;
}

.input-box {
    display: flex;
    align-items: center;
    background: #F7F7F7;
    border: unset;
    border-radius: 5px;
    padding-left: 0.5rem;
    overflow: hidden;
    font-family: sans-serif;
    border-bottom: 3px solid #3AA38D;
}

.input-box .prefix {
    font-weight: 300;
    color: #999;
    margin-right: 8px;
}

.otp-number {
    width: 15%;
    height: 40px;
    background-color: #F7F7F7;
    border: unset;
    border-radius: 5px;
    outline: none;
    text-align: center;
    font-size: 16px;
    border: 1px solid #F7F7F7;
}

.input-box .suffix {
    font-weight: 300;
    font-size: 10px;
    padding-right: 15px;
}

.toast-container {
    @media (min-width: 767px) {
        width: 30%;
    }
}